import PropTypes from 'prop-types'

import Button from 'common/Button'

import classReader from 'utils/classReader'

const SwiperButton = (props) => {
  const {
    className,
    id,
    icon,
    onClick,
  } = props

  return (
    <div
      className={classReader(
        'd-lg-flex',
        { IndexStyled: 'swiper-button' },
        className,
      )}
      id={id}
    >
      <Button
        className={classReader('bg-white', { IndexStyled: 'swiper-button--size' })}
        styleName="IndexStyled"
        icon={icon}
        color="white"
        textColor=" icon-primary text-gray-3"
        onClick={onClick}
        size="lg"
        outline
        round
        unelevated
        closeAnimate
      />
    </div>
  )
}

SwiperButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
}

SwiperButton.defaultProps = { onClick: () => { } }

export default SwiperButton
