import { memo } from 'react'
import PropTypes from 'prop-types'
import classReader from 'utils/classReader'

const Badge = (props) => {
  const {
    className,
    id,
    color,
    textColor,
    outline,
    floating,
    rounded,
    children,
  } = props


  const badgeColor = outline ? color || textColor : textColor
  const badgeShape = floating && rounded ? 'circle' : 'default'
  return (
    <div
      className={classReader(
        'badge no-wrap badge--single-line',
        `badge--${badgeShape}-padding`,
        { [`text-${badgeColor}`]: Boolean(badgeColor) },
        { [`bg-${color}`]: Boolean(color) && outline === false },
        { 'badge--outline': outline },
        { 'badge--floating': floating },
        { 'badge--rounded': rounded },
        className,
      )}
      id={id}
    >
      {children}
    </div>
  )
}

Badge.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string,
  outline: PropTypes.bool,
  floating: PropTypes.bool,
  rounded: PropTypes.bool,
  children: PropTypes.node,
}

Badge.defaultProps = {
  outline: false,
  floating: false,
  rounded: false,
}

export default memo(Badge)