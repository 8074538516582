import React from 'react'
import Image from 'next/image'
import classReader from './classReader'
import { BR_STRING } from 'config/htmlParse'
import { SHOW_CONSOLE } from 'config/config'
import DOMPurify from 'dompurify'
import parse from 'html-react-parser'

export const jsDOMPurify = (html) => {
  if (!html) return 
  
  html = DOMPurify.sanitize(html)

  /* 針對超連結進行過濾
   *  1. 刪除 style、target 屬性
   *  2. 添加 classname，
   *  3. 指定超連結 target="_blank"
   */
  const linkHrefRegex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1[^>]*>(.*?)<\/a>/gi
  html = html.replace(linkHrefRegex, `<a href="$2" class="${classReader('d-inline-block text-primary')}" target="_blank">$3</a>`)

  // 將 p (段落內容) 中的破折號替換為空字符串
  const paragraphRegex = /<p>(.*?)<\/p>/gi
  html = html.replace(paragraphRegex, (match, p1) => {
    return `<p>${p1.replace(/－/g, '')}</p>`
  })

  /* 針對 h4 文字中出現的關鍵字進行以下替換
   *  1. "包含" 替換 "費用包含"
   *  2. "不包含" 替換 "費用不包含"
   */
  const h4Regex = /<h4>(.*?)<\/h4>/gi
  html = html.replace(h4Regex, (match, h4Content) => {

    let h4Array = h4Content.replace(/不包含|包含/g, (match) => {
      if (match === '不包含') return `<h4 class="${classReader('exclude')}">費用不包含</h4>`
      if (match === '包含') return `<h4 class="${classReader('include')}">費用包含</h4>`
    }).replaceAll('費用<h4', '<h4')

    // h4Array = h4Array.replace(h4ClassNameRegex, `<h4 class="${classReader('include')}">$3</h4>`)
  
    return h4Array
  })


  // 處理 h3，將 KKDAY 的標題 「費用包含/不包含」，改成雄獅的「費用明細」
  const h3Regex = /<h3>(.*?)<\/h3>/gi
  html = html.replace(h3Regex, (match, h3Content) => `<h3>${h3Content.replace('費用包含/不包含', '－ 費用明細 －')}</h3>`)
  
  return parse(html)
}

export const objectToJSX = (dataInput) => {
  if (!dataInput) return 

  const convertToJSX = (data) => {
    if (typeof data === 'string') {
      return data
    } else if (Array.isArray(data)) {
      return data.map((item, index) => (
        convertToJSX(item)
      ))
    } else if (typeof data === 'object') {
      const {
        tagName, tagContent, key, ...attributes
      } = data
                
      const elementName = tagName
      const elementProps = { ...attributes }
      const children = Array.isArray(tagContent)
        ? tagContent.map((child, index) => (
          convertToJSX(child)
        ))
        : convertToJSX(tagContent)
      let ModifiedChildren = children
      if (!tagName || (tagName === 'p' && !children) || !checkIsHTMLtag(tagName) || tagName === 'meta' || tagName === 'hr' || tagName === 'br') {
        return null
      }
        
      const elementPropKeys = Object.keys(elementProps)
      if (elementPropKeys.includes('title')) delete elementProps.title
      if (elementPropKeys.includes('style')) delete elementProps.style
      if (elementPropKeys.includes('easonclass')) elementProps['className'] = classReader(elementProps.easonclass)

      if (elementName === 'img') {
        return <Image 
          key={key} 
          src={elementProps.src} 
          alt={elementProps?.alt || 'img'} 
          width={300} 
          height={300} 
          priority 
          style={{
            width: '100%',
            height: 'auto', 
          }} />
      }

      if (elementName === 'h3' && children === '費用包含/不包含') {
        // 將 KKDAY 的標題 「費用包含/不包含」，改成雄獅的「費用明細」
        ModifiedChildren = '－ 費用明細 －'
      }

      if (elementName === 'h4' && (children === '包含' || children === '費用包含')) {
        elementProps['className'] = classReader('include')
      }

      if (elementName === 'h4' && (children === '不包含' || children === '費用不包含')) {
        elementProps['className'] = classReader('exclude')
      }

      if (elementName === 'p' && children.includes('－') ){
        ModifiedChildren = children.replaceAll('－', '')
      }

      if (elementName === 'a'){
        elementProps['target'] = '_blank'
        elementProps['className'] = classReader('d-inline-block')
      }

      if (elementName === 'body') {
        return children
      }
      
      return React.createElement(
        elementName,
        elementProps ? {
          ...elementProps,
          key, 
        } : null,
        ModifiedChildren,
      )
    }
  }

  try {
    const htmlInputWithPreprocess = dataInput.replaceAll('<p><p>', '<p>').replaceAll('</p></p>', '</p>').replaceAll('\\"', '"').replaceAll('&nbsp;', '').replaceAll('&trade;', '').replaceAll('⮩', '').replace(BR_STRING, '')
    const data = parseHTML(htmlInputWithPreprocess)
    const result = convertToJSX(data)
    return result
  }
  catch (error){
    if (SHOW_CONSOLE) console.log('error', error)
  }
}

const checkIsHTMLtag = (tagName) => {
  const htmlTags = [
    'a',
    'abbr',
    'acronym',
    'address',
    'applet',
    'area',
    'article',
    'aside',
    'audio',
    'b',
    'base',
    'basefont',
    'bdi',
    'bdo',
    'big',
    'blockquote',
    'body',
    'br',
    'button',
    'canvas',
    'caption',
    'center',
    'cite',
    'code',
    'col',
    'colgroup',
    'data',
    'datalist',
    'dd',
    'del',
    'details',
    'dfn',
    'dialog',
    'dir',
    'div',
    'dl',
    'dt',
    'em',
    'embed',
    'fieldset',
    'figcaption',
    'figure',
    'font',
    'footer',
    'form',
    'frame',
    'frameset',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'head',
    'header',
    'hr',
    'html',
    'i',
    'iframe',
    'img',
    'input',
    'ins',
    'kbd',
    'label',
    'legend',
    'li',
    'link',
    'main',
    'map',
    'mark',
    'meta',
    'meter',
    'nav',
    'noframes',
    'noscript',
    'object',
    'ol',
    'optgroup',
    'option',
    'output',
    'p',
    'param',
    'picture',
    'pre',
    'progress',
    'q',
    'rp',
    'rt',
    'ruby',
    's',
    'samp',
    'script',
    'section',
    'select',
    'small',
    'source',
    'span',
    'strike',
    'strong',
    'style',
    'sub',
    'summary',
    'sup',
    'svg',
    'table',
    'tbody',
    'td',
    'template',
    'textarea',
    'tfoot',
    'th',
    'thead',
    'time',
    'title',
    'tr',
    'track',
    'tt',
    'u',
    'ul',
    'var',
    'video',
    'wbr',
  ]
  return htmlTags.includes(tagName)
}

const listAttributes = (element) => {
  if (element instanceof Element) {
    const attributes = element.attributes
    const attributeList = []
  
    for (let i = 0; i < attributes.length; i++) {
      const attribute = attributes[i]
      attributeList.push(attribute.name)
    }
    return attributeList

  } else {
    return null 
  }
}

const parseStyleString = (styleStr) => {
  if (!styleStr) return
  const styleObj = {}
  const declarations = styleStr.split(';')
  for (const declaration of declarations) {
    if (declaration) {
      const [property, value] = declaration.split(':')
      const propertyName = property.trim()
      let propertyValue = value.trim()
      if (propertyName === 'color' || propertyName.endsWith('color')) {
        const colorValue = propertyValue.replace(/\s/g, '')
        if (colorValue.startsWith('rgb(') || colorValue.startsWith('rgba(')) {
          propertyValue = `rgba${colorValue.substring(3)}`
        }
      }
      styleObj[propertyName] = propertyValue
    } 
  }
  return styleObj
}
   
const parseHTML = (html) => {
  if (!html) return 
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  const parseElement = (element) => {

    const tagName = element.tagName.toLowerCase()
    const className = element.getAttribute('class') || ''
    const styleStr = element.getAttribute('style') || ''
    const key = tagName + className + styleStr + Math.floor(Math.random() * 9999999)
    const attrs = listAttributes(element)
    const styleObj = parseStyleString(styleStr)

    const result = attrs.reduce((acc, curr) => {
      if (curr !== 'style'){
        acc[curr] = element.getAttribute(`${curr}`).replaceAll('\\"', '') || ''
      }
      return acc
    }, {
      key,
      tagName,
      style: { ...styleObj }, 
    })

    if (element.children.length > 0) {
      result.tagContent = Array.from(element.children).map(parseElement)
    }
    else {
      result.tagContent = element.textContent.trim()      
    }
    return result
  }

  const wrapperElement = parseElement(doc.body)

  return wrapperElement
}